.sideDrawer,
.sideDrawer .MuiPaper-root {
    width: 70px;
    transition: 0.2s;
}

.sideDrawer.opened,
.sideDrawer.opened .MuiPaper-root {
    width: 240px;
}

.sideDrawer.opened .MuiListItemText-root {
    opacity: 1;
}

.sideDrawer .MuiListItemText-root {
    transition: 0.2s;
    opacity: 0;
}

.sideDrawer .logo-wrapper img {
    max-width: 220px;
    min-width: 220px;
    object-fit: contain;
    object-position: left;
}

.sideDrawer.opened .logo-wrapper img {
    max-width: 220px;
    min-width: 220px;
}

.logo-icon {
    object-fit: contain;
    height: 40px;
    max-width: 50px;
    min-width: 50px;
    margin: 0 10px;
    display: block;
}

.logo-full {
    object-fit: contain;
    margin: 0 10px;
    display: block;
    max-width: 220px;
    min-width: 220px;
}

.logo-wrapper {
    height: 52.5px;
}

.MuiTableCell-root.filters {
    padding: 5px 8px;
}

.myTable .MuiTableCell-root {
    padding: 8px 16px;
}

.myLink {
    cursor: pointer;
    border-bottom: 1px dashed;
    display: inline-block;
}

.ql-editor {
    min-height: 180px;
}

.ce-block__content {
    position: relative;
    max-width: 100% !important;
    margin: 0 auto;
    -webkit-transition: background-color 0.15s ease;
    transition: background-color 0.15s ease;
}

.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #282a36;
    height: 500px !important;
}

tr.MuiTableRow-root > th {
    text-transform: uppercase;
}

body .tox-tinymce {
    border-radius: 0;
}

.CloseImageIcon {
    position: absolute !important;
    right: 0;
    border: 1px solid #7a7a7a !important;
    color: #7a7a7a !important;
}
/* 
fieldset legend span {
    opacity: 1 !important;
} */
.helper-text {
    color: rgba(0, 0, 0, 0.6);
    font-family: Inter;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin: 3px 14px 0px;
}
